<nb-layout  class=".col-12 .col-sm-6 .col-md-8">

  <nb-layout-header fixed>
    <app-header style="width: 100%;"></app-header>
  </nb-layout-header>

  <nb-sidebar tag="left" responsive>
    <button nbButton (click)="toggle()" *ngIf="!isToggled" class="sidebar-toggle" size="tiny">
      <nb-icon *ngIf="!isToggled" icon="arrowhead-left-outline"></nb-icon>
  </button>
  <button nbButton (click)="toggle()" *ngIf="isToggled" class="sidebar-toggle" size="tiny">
    <nb-icon  icon="arrowhead-right-outline"></nb-icon>
</button>
    <app-menu></app-menu>
    <!-- <ng-content select="nb-menu"></ng-content> -->
  </nb-sidebar>

  <nb-layout-column>
    
    <!-- <nb-card *ngIf="showHeaderButtons()">
      <nb-card-body>
        Header with buttoms
        <div class="col-12">
          <button nbButton status="primary" type="button" outline="true" size="small" routerLink="/load/loads"
            queryParamsHandling="preserve"> Exit
          </button>
        </div>

      </nb-card-body>
    </nb-card> -->

    <nb-card class="Card">
      <nb-card-body>
        <router-outlet></router-outlet>
      </nb-card-body>
    </nb-card>
    
  </nb-layout-column>
  <!-- <nb-layout-footer><app-footer></app-footer></nb-layout-footer> -->
</nb-layout>