import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlService {
  constructor() { }

  LoadApi: string = 'http://localhost:50848/api';
  // LoadApi: string = 'http://192.168.1.51:8074/api';

  ConfigurationApi: string = 'http://localhost:50117/api';
  // ConfigurationApi: string = 'http://192.168.1.51:8073/api';

  DriverApi: string = 'http://localhost:58823/api';
  // DriverApi: string = "http://192.168.1.51:8072/api";

  AssetsAndMaintanceApi: string = 'http://localhost:49987/api';
  // AssetsAndMaintanceApi: string = 'http://192.168.1.51:8075/api';

  IdentityApi: string = 'http://localhost:58754/api';
  //IdentityApi: string = 'http://192.168.1.51:8071/api';

  LoadFinanceApi: string = 'http://localhost:57390/api';
  // LoadFinanceApi: string = 'http://192.168.1.51:8075/api';
}


