import { NgModule } from '@angular/core';
import { NbButtonModule, NbCheckboxModule, NbInputModule } from '@nebular/theme';

@NgModule({
    declarations: [],
    imports: [
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule

    ],
    exports: [
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule
    ],
    providers: []
})
export class NebularSharedForm { }