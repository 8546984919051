<div class="row">
    <div class="col-md-12">
        <div class="col-md-4 offset-8 Time">
            <label>Choose time interval in days</label>
            <ng-select [items]="options" bindLabel="value" bindValue="value" [(ngModel)]="dayInterval.days" name="days" (change)="onchange(dayInterval.days)"
            (clear)="onClear()"></ng-select>
        </div>
    </div>
    
</div>
<div class="row">
    <div class="col-md-7">
        <nb-card class="Loads">
            <nb-card-header>Loads</nb-card-header>
                <nb-card-body>
                    <ngx-charts-pie-chart 
                [results]="loads"
                [legend]="true"
                [legendTitle]="'Loads activity'" 
                [view]="[800,350]"
                [labels]="true" >
                </ngx-charts-pie-chart>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-5">
        <nb-card >
            <nb-card-header>Accounting</nb-card-header>
            <nb-card-body >
                    <ngx-charts-pie-grid 
                        [results]="accountings"
                        [view]="[580,350]" >
                    </ngx-charts-pie-grid>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-12">
        <nb-card >
            <nb-card-header>
                Profits
            </nb-card-header>
            <nb-card-body>
                <div class="row">
                    <ngx-charts-pie-chart 
                [results]="loadForProfits"
                [legend]="true"
                [legendTitle]="'Loads'" 
                [view]="[600,350]"
                [labels]="true" >
                </ngx-charts-pie-chart>
               
                <ngx-charts-bar-vertical 
                    [view]="[800,350]"
                    [results]="profits"
                    [xAxisLabel]="'Profits'"
                    [legendTitle]="'Profit Activity Chart'"
                    [yAxisLabel]="'Quantity'"
                    [legend]="true"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [gradient]="true"
                    [roundEdges]="false">
                </ngx-charts-bar-vertical>
                </div>
                
            </nb-card-body>
        </nb-card>
        
    </div>
</div>

    
