import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private router: Router,
  ) {
  }

  items: NbMenuItem[] = [
    { title: 'Dashboard', icon: 'home-outline', link: '/home' },
    {
      title: 'Loads', icon: 'car-outline', expanded: true, children: [
        { title: 'Active loads', link: '/load/loads' },
        { title: 'Delivered loads', link: '/load/deliveredLoads' },
        { title: 'Archived loads', link: '/load/listArchivedLoads' },
        { title: 'Deactivated loads', link: '/load/listDeactivatedLoads' },
        { title: 'Reports', link: '/load/loadReports' }
      ]
    },
    {
      title: 'Load settings', icon: 'settings-2-outline', expanded: true, children: [
        { title: 'Handling Units', link: '/load/handlingUnits' },
        { title: 'Commodities', link: '/load/commodities' },
        { title: 'Delivery Statusses', link: '/load/deliveryStatuses' },
        { title: 'Locations', link: '/load/locations' },
        { title: 'NMFC Class Units', link: '/load/showNMFCClasses' },
      ]
    },
    {
      title: 'Accounting', icon: 'clipboard-outline', expanded: false, children: [
        { title: 'Loads', link: '/accounting/loadsAccounting' },
        { title: 'Reports', link: '/accounting/financeReport' }
      ]
    },
    {
      title: 'Driver', icon: 'person-outline', expanded: false, children: [
        { title: 'Drivers', link: '/driver/drivers' },
        { title: 'Emergency Contact', link: '/driver/emergencyContact' },
        //TODO: sertificates .....
        { title: 'Certificates', link: '/driver/sertificates' }
      ]
    },
    {
      title: 'Configuration', icon: 'settings-2-outline', expanded: false, children: [
        { title: 'View Organization', link: '/configuration/myOrganization' },
        { title: 'Business Associate', link: '/configuration/businessAssociate' },
        { title: 'Equipment Type', link: '/configuration/equipmentType' },
        { title: 'Equipment Length', link: '/configuration/equipmentLength' },
        { title: 'Contact', link: '/configuration/contact' },
        { title: 'Global Config', link: '/configuration/globalConfig' },
      ]
    },
    {
      title: 'Trucks And Trailers', icon: 'car-outline', expanded: false, children: [
        { title: 'Service Items', link: '/assets/serviceItem' },
        { title: 'Trailer Type', link: '/assets/TrailerType' },
        { title: 'Trailers', link: '/assets/trailer' },
        { title: 'Power Units', link: '/assets/powerUnit/list-power-unit' },
        { title: 'Plan', link: '/assets/plan' },
        { title: 'Service Appointments', link: '/assets/appointments' },
        { title: 'Service Log', link: '/assets/serviceLog' }
      ]
    },
    {
      title: 'Account', icon: 'people-outline', expanded: false, children: [
        { title: 'Users', link: '/account/listAccounts' }
      ]
    }
  ]
  ngOnInit(): void {
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
}
