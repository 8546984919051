import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  authToken = "Нема најавен корисник";

  ngOnInit(): void {
    let temp = localStorage.getItem("AuthToken");
    if (temp !== null && temp !== undefined)
      this.authToken = temp;
  }

}
