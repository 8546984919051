import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Components/layout/header/header.component';
import { MenuComponent } from './Components/menu/menu.component';
import { LogInComponent } from './Components/log-in/log-in.component';
import { AuthLayoutComponent } from './Components/layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './Components/layout/main-layout/main-layout.component';
import { CustomDateParserFormatter } from './Models/Infrastructure/CustomDateParserFormatter';
import { httpInterceptorProviders } from './http-interceptors/all-interceptors';
import { TrueFalsePipe } from './Helpers/custom-pipes';
import { NebularCustomMainModule } from './SharedModules/nebularCustomMainModule.module';
import { NbDatepickerModule, NbDialogModule, NbLayoutModule, NbMenuModule, NbTableModule, NbTabsetModule, NbToastrModule } from '@nebular/theme';
import { ColorPickerModule } from 'ngx-color-picker';
import { ConfirmDialogComponent } from './Components/confirm-dialog/confirm-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NebularSharedForm } from './SharedModules/nebularSharedForm.module';
import { ConfirmEqualValidatorDirective } from './Helpers/confirm-equal-validator.directive';
import { FooterComponent } from './Components/layout/footer/footer.component';
import { HomeComponent } from './Components/layout/home/home.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';






@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TrueFalsePipe,
    LogInComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    MenuComponent,
    ConfirmDialogComponent,
    ConfirmEqualValidatorDirective,
    FooterComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NebularCustomMainModule,
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot({ autoFocus: false, hasBackdrop: true, closeOnBackdropClick: false }),
    ColorPickerModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    NbToastrModule.forRoot(),
    NebularSharedForm,
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbTabsetModule,
    NgxChartsModule
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

