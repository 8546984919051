import { NgModule } from '@angular/core';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule, NbLayoutModule, NbMenuModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';

@NgModule({
    declarations: [],
    imports: [
        NbThemeModule.forRoot({ name: 'default' }),
        NbLayoutModule,
        NbSidebarModule.forRoot(),
        NbEvaIconsModule,
        NbMenuModule.forRoot(),
        NbCardModule,
        NbDatepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({ format: 'MM\\dd\\yyyy' }),
        NbInputModule,
        NbIconModule,
    ],
    exports: [
        NbThemeModule,
        NbLayoutModule,
        NbSidebarModule,
        NbEvaIconsModule,
        NbMenuModule,
        NbCardModule,
        NbDatepickerModule,
        NbDateFnsDateModule,
        NbInputModule,
        NbIconModule,
    ],
    providers: []
})
export class NebularCustomMainModule { }