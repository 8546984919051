<form>
    <div class="row">
        <div class="col-lg-6 col-xl-5 col-md-9 mx-auto">
            <div class="card mt-4">
                <div class="card-header">
                    <h4>Login</h4>
                </div>
                <div class="card-body">
                    <form role="form" #MySelectForm="ngForm" (submit)="onFormSubmit()">
                        <div class="form-group form-row">
                            <label for="username" class="col-md-4 col-form-label">Username</label>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="logInRequestItem.username" class="form-control"
                                    name="userName" autofocus>
                            </div>
                        </div>
                        <div class="form-group form-row">
                            <label for="password" class="col-md-4 col-form-label">Password</label>
                            <div class="col-md-8">
                                <input type="password" [(ngModel)]="logInRequestItem.password" class="form-control"
                                    name="password" />
                            </div>
                        </div>
                        <div class="form-group pTop-15">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="rememberMe" name="rememberMe"
                                    [(ngModel)]="logInRequestItem.rememberMe">
                                <label class="form-check-label" for="inlineCheckbox1">Keep me signed in!</label>
                            </div>
                            <button class="btn btn-primary float-right" type="submit">
                                Login
                            </button>
                        </div>

                        <div class="alert alert-danger" role="alert" *ngIf="this.logInMessage!== undefined">
                            {{this.logInMessage}}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</form>