import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './Components/layout/auth-layout/auth-layout.component';
import { LogInComponent } from './Components/log-in/log-in.component';
import { MainLayoutComponent } from './Components/layout/main-layout/main-layout.component';
import { AuthGuard, AuthGuardChild } from './Guards/auth.guard';
import { HomeComponent } from './Components/layout/home/home.component';

const routes: Routes = [
  

  {
    path: 'auth', component: AuthLayoutComponent,
    children: [
      {
        path: 'login', component: LogInComponent
      }
    ]
  },
  {
    
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuardChild],
    children: [
      {path:'home', component:HomeComponent},
      { path: 'configuration', loadChildren: () => import('./Modules/configurationApi/configurationApi.module').then(m => m.ConfigurationApiModule) },
      { path: 'driver', loadChildren: () => import('./Modules/DriverApi/DriverApi.module').then(m => m.DriverApiModule) },
      { path: 'assets', loadChildren: () => import('./Modules/TrucksAndTrailersApi/TrucksAndTrailersApi.module').then(m => m.TrucksAndTrailersApiModule) },
      { path: 'load', loadChildren: () => import('./Modules/LoadApi/LoadApi.module').then(m => m.LoadApiModule) },
      { path: 'accounting', loadChildren: () => import('./Modules/FinanceApi/FinanceApi.module').then(m => m.FinanceApiModule) },
      { path: 'account', loadChildren: () => import('./Modules/IdentityApi/Identity-Api.module').then(m => m.IdentityApiModule) }
    ]
  },

];



@NgModule({

  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]

})

export class AppRoutingModule { }
