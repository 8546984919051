import jwtDecode from "jwt-decode";
import { JWTModel } from "../Models/Infrastructure/JWTModel";
import { UserRoleModel } from "../Models/UserRoleModel";

export class DecodeToken {
    static getUserInfo(): JWTModel {

        let token = localStorage.getItem("AuthToken")
        if (token != undefined && token != null) {
            return jwtDecode<JWTModel>(token)
        }
        return null;



    }
}
