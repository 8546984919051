import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChartsLoadModel } from 'src/app/Models/ChartsLoadModel';
import { DaysIntervalChartRequest } from 'src/app/Models/DaysIntervalChartRequest';
import { ApiUrlService } from '../Infrastructure/apiurl.service';

@Injectable({
  providedIn: 'root'
})
export class LoadChartService {

  constructor(private http:HttpClient, private apiUrl:ApiUrlService) { }
  private loadChartUrl = this.apiUrl.LoadApi +"/Chart"
  getLoadsChart(days:DaysIntervalChartRequest):Observable<ChartsLoadModel>{
    return this.http.post<ChartsLoadModel>(this.loadChartUrl+"/GetLoadsChart", days)
  }
}
