import { Component, OnInit } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { DecodeToken } from 'src/app/Helpers/decode-token';
import { AddAccountModel } from 'src/app/Models/AddAccountModel';
import { IdRequestModel } from 'src/app/Models/IdRequestModel';
import { JWTModel } from 'src/app/Models/Infrastructure/JWTModel';
import { UserRoleModel } from 'src/app/Models/UserRoleModel';
import { IdentityService } from 'src/app/Services/Identity/identity.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  constructor(private sidebarService: NbSidebarService,
    private identityService:IdentityService) { }


  authToken = "Нема најавен корисник";
  userInfo : JWTModel;
  idRequest:IdRequestModel={
    id:0
  }
  user:AddAccountModel={
    id:0,
    userName:"",
    password:"",
    salt:"",
    confirmPassword:"",
    firstName:"",
    lastName:"",
    userRoles:[],
  };
  ngOnInit(): void {
    this.userInfo = DecodeToken.getUserInfo();
    this.idRequest.id = this.userInfo.UserId;
    this.identityService.getUserById(this.idRequest).subscribe(x=>{
      this.user = x
    })
    
    let temp = localStorage.getItem("AuthToken");
    if (temp !== null && temp !== undefined)
      this.authToken = temp;
  }

  toggle() {
    this.sidebarService.toggle(true, 'left');
  }
  onLogoutClick(){
    this.identityService.logOut();
  }

}
