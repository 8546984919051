import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  isToggled:boolean;
  constructor(
    private router: Router,
    private sidebarService: NbSidebarService,
  ) { }
    
  ngOnInit(): void {
  }


  // showHeaderButtons() {
  //   if (this.router.url.includes('/editLoad/') || this.router.url.includes("createLoad")) {
  //     return true;
  //   }
  //   return false;
  // }
  toggle() {
    this.sidebarService.toggle(true, 'left');
    this.isToggled = !this.isToggled;
  }

}
