import { Component, OnInit } from '@angular/core';
import { AccountingChartResponseModel } from 'src/app/Models/AccountingChartResponseModel';
import { ChartsLoadModel } from 'src/app/Models/ChartsLoadModel';
import { DaysIntervalChartRequest } from 'src/app/Models/DaysIntervalChartRequest';
import { ProfitResponseModel } from 'src/app/Models/ProfitResponseModel';
import { AccountChartService } from 'src/app/Services/FinanceApi/account-chart.service';
import { LoadChartService } from 'src/app/Services/LoadApi/load-chart.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private loadChartService:LoadChartService, private accountingChartService:AccountChartService) {
    
  }
  loadsModel:ChartsLoadModel;
  accountingModel:AccountingChartResponseModel;
  profitModel:ProfitResponseModel;

  dayInterval:DaysIntervalChartRequest={
    days:0
  };
  loads=[];
  accountings=[];
  profits=[];
  loadForProfits = [];
  options=[
    
    {
     value:10
  },
  {
   value:20
  },
  {
  value:30
  }];
  ngOnInit(): void {
      this.loadChartService.getLoadsChart(this.dayInterval).subscribe(x=>{
      
        this.loadsModel = x;
        const loads = [
          { name: "Active Loads", value: this.loadsModel.activeLoads },
          { name: "Archived Loads", value: this.loadsModel.archivedLoads },
          { name: "Deactivated Loads", value: this.loadsModel.deactivatedLoads },
          
        ];
        this.loads = loads;
      })
      this.accountingChartService.getAccountingChart(this.dayInterval).subscribe(x=>{
        console.log(111, x)
        this.accountingModel = x;
        const accounting=[
          { name: "Total charged invoices", value: this.accountingModel.totalChargedInvoices},
          { name: "Total charged receipts", value: this.accountingModel.totalChargedReceipts},
          { name: "Total invoice payments", value: this.accountingModel.totalInvoicePayments},
          { name: "Total receipt payments", value: this.accountingModel.totalReceiptPayments},
          { name: "Total incomes", value: this.accountingModel.totalIncomes},
          { name: "Total expenses", value:  this.accountingModel.totalExpenses}
        ];
        this.accountings = accounting;
      })
      this.accountingChartService.getProfit(this.dayInterval).subscribe(x=>{
        this.profitModel = x
        const profit =[
          {name: "Carrier total charged", value: this.profitModel.carrierTotalCharged},
          {name: "Carrier total payed", value: this.profitModel.carrierTotalPayed},
          {name: "Company total charged", value: this.profitModel.companyTotalCharged},
          {name: "Company total payed", value: this.profitModel.companyTotalPayed}
        ]
        const load = [
          {name: "Loads with carrier", value: this.profitModel.loadsWithCarrier},
          {name: "Loads with company", value: this.profitModel.loadsWithCompany}
        ]
        this.profits = profit;
        this.loadForProfits = load;
      })
      
    
   
  }
  
  onchange(value:number){
    this.loads = [];
    this.accountings = [];
    this.dayInterval.days = value;
    if(value == null){
      this.dayInterval.days = 0
    }
    this.loadChartService.getLoadsChart(this.dayInterval).subscribe(x=>{
      this.loadsModel = x;
      
      const loads = [
        { name: "Active Loads", value: this.loadsModel.activeLoads },
        { name: "Archived Loads", value: this.loadsModel.archivedLoads },
        { name: "Deactivated Loads", value: this.loadsModel.deactivatedLoads},
        
      ];
      this.loads = loads;
    })
    this.accountingChartService.getAccountingChart(this.dayInterval).subscribe(x=>{
      this.accountingModel = x;
     
      const accounting=[
        { name: "Total charged invoices", value: this.accountingModel.totalChargedInvoices},
        { name: "Total charged receipts", value: this.accountingModel.totalChargedReceipts},
        { name: "Total invoice payments", value: this.accountingModel.totalInvoicePayments},
        { name: "Total receipt payments", value: this.accountingModel.totalReceiptPayments},
        { name: "Total incomes", value: this.accountingModel.totalIncomes},
          { name: "Total expenses", value: this.accountingModel.totalExpenses}
      ];
      this.accountings = accounting;
    })
    this.accountingChartService.getProfit(this.dayInterval).subscribe(x=>{
      this.profitModel = x
      const profit =[
        {name: "Carrier total charged", value: this.profitModel.carrierTotalCharged},
        {name: "Carrier total payed", value: this.profitModel.carrierTotalPayed},
        {name: "Company total charged", value: this.profitModel.companyTotalCharged},
        {name: "Company total payed", value: this.profitModel.companyTotalPayed}
      ]
      const load = [
        {name: "Loads with carrier", value: this.profitModel.loadsWithCarrier},
        {name: "Loads with company", value: this.profitModel.loadsWithCompany}
      ]
      this.profits = profit;
      this.loadForProfits = load;
    })
    
  }
  onClear(){
    this.loads = [];
    this.accountings = [];
    this.loadChartService.getLoadsChart(this.dayInterval).subscribe(x=>{
      
      this.loadsModel = x;
      
      
      const loads = [
        { name: "Active Loads", value: this.loadsModel.activeLoads },
        { name: "Archived Loads", value: this.loadsModel.archivedLoads},
        { name: "Deactivated Loads", value: this.loadsModel.deactivatedLoads },
        
      ];
      this.loads = loads;
    })
    this.accountingChartService.getAccountingChart(this.dayInterval).subscribe(x=>{
      this.accountingModel = x;
      const accounting=[
        { name: "Total charged invoices", value: this.accountingModel.totalChargedInvoices},
          { name: "Total charged receipts", value: this.accountingModel.totalChargedReceipts},
          { name: "Total invoice payments", value: this.accountingModel.totalInvoicePayments},
          { name: "Total receipt payments", value: this.accountingModel.totalReceiptPayments},
          { name: "Total incomes", value: this.accountingModel.totalIncomes},
          { name: "Total expenses", value: this.accountingModel.totalExpenses}
      ];
      this.accountings = accounting;
    })
    this.accountingChartService.getProfit(this.dayInterval).subscribe(x=>{
      this.profitModel = x
      const profit =[
        {name: "Carrier total charged", value: this.profitModel.carrierTotalCharged},
        {name: "Carrier total payed", value: this.profitModel.carrierTotalPayed},
        {name: "Company total charged", value: this.profitModel.companyTotalCharged},
        {name: "Company total payed", value: this.profitModel.companyTotalPayed}
      ]
      const load = [
        {name: "Loads with carrier", value: this.profitModel.loadsWithCarrier},
        {name: "Loads with company", value: this.profitModel.loadsWithCompany}
      ]
      this.profits = profit;
      this.loadForProfits = load;
    })
  }
}
