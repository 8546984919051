import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {


  constructor(protected dialogRef: NbDialogRef<ConfirmDialogComponent>, private dialogService: NbDialogService,) {
  }

  message: string;
  myMessage
  toBeDeleted:boolean = false;
  ngOnInit(): void {


    this.myMessage = "Are you sure you want to delete this item ?";
    if (this.message != null) {
      this.myMessage = this.message;

    }
  }

  decline() {
    this.dialogRef.close(false);
  }

  accept() {
    this.toBeDeleted = true;
    this.dialogRef.close(true);
  }

  dismiss() {
    this.dialogRef.close(false);
  }



}
