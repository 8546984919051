import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from 'luxon';
import { DropDownModel } from "../Models/DropDownModel";



@Pipe({
    name: "trueFalse"
})
export class TrueFalsePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        return value ? "Yes" : "No";
    }
};


@Pipe({
    name: 'dateToLocal'
})
export class DateToLocalPipe implements PipeTransform {
    transform(value: string): string {
        return DateTime.fromISO(value + 'Z').toLocaleString();
    }
};



@Pipe({
    name: "idToName"
})
export class idToNamePipe implements PipeTransform {
    transform(value: number, list: DropDownModel[]): string {
        if (list !== undefined && value !== undefined) {
            if (list.find(x => x.id == value) !== undefined)
                return list.find(x => x.id == value).name;
        }
    }
};


@Pipe({
    name: "formatLoadNUmber"
})
export class FormatLoadNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (value === 0)
            return "";

        let digitCount = value.toString().length;
        let loadNumber: string = "#";

        for (let i = 0; i < 7 - digitCount; i++) {
            loadNumber += '0';
        }
        loadNumber += value.toString();

        return loadNumber;
    }
};