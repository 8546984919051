import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginRequestModel } from 'src/app/Models/LoginRequestModel';
import { IdentityService } from 'src/app/Services/Identity/identity.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {

  constructor(private router: Router, private IdentityService: IdentityService) { }

  logInRequestItem: LoginRequestModel = {
    username: "",
    password: "",
    rememberMe: false
  };

  logInMessage: string;

  ngOnInit(): void {
    localStorage.removeItem("AuthToken");
  }

  onFormSubmit() {
    this.IdentityService.logIn(this.logInRequestItem).subscribe(x => {
      if (x.authToken !== undefined) {
        localStorage.setItem("AuthToken", x.authToken);
        this.router.navigate(['/home'])
      }
      else {
        this.logInMessage = "Invalid username/password";
      }
    });
  }
}


