<div class="header-container" style="width: 100%;">

    <div class="row col-md-12 ">
        <div class="col-md-1">
            <div class="logo-container">
                <img src="assets/logo-01.png" class="responsive" width="90px" />
            </div>

        </div>
        <div class="col-md-8">
            <!-- <p class="BasicHeader">{{authToken}}</p> -->
        </div>
        <div class="col-md-3  UserHolder">


            <div class="btn-group mr-3 ">
                <button type="button" class="btn btn-ghost-success">{{user.firstName}}
                    {{user.lastName}}
                </button>
                <div class="btn-group Toggle" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                    <button class="btn btn-ghost-primary" ngbDropdownToggle>
                        <i class="fas fa-user"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>


                        <button ngbDropdownItem nbButton status="primary" size="small" ghost (click)="onLogoutClick()">
                            <nb-icon icon="log-out-outline"></nb-icon> Log out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>